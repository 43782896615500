import React from "react"
import * as styles from "./styles.module.scss"

import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage } from 'gbimage-bridge';

const ThanksForYourSupport = () => {
  // Gatsby 3 及び gatsby-plugin-image とともに、gatsby-background-image V1.5 を使おうと思った場合、次のメジャーバージョンまでは、暫定的に gbimage-bridge と呼ばれるコンパニオンパッケージを使う必要があります
  // https://www.gatsbyjs.com/plugins/gatsby-background-image/#gatsby-3--gatsby-plugin-image
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "cases-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 608
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)

  const bgImage = convertToBgImage(image)

  return (
    <>
      <section className={styles.section}>
        <div className={styles.wrap}>
          <BackgroundImage
            Tag="div"
            // Spread bgImage into BackgroundImage:
            {...bgImage}
            preserveStackingContext
            className={styles.cases}
          >
          </BackgroundImage>
        </div>

        <div className={styles.content}>
          <h2>みなさまに支えられて</h2>
          <p>2014年のリリース以来、Azusaは「大体いい感じになるテンプレート」として、さまざまなスライドに利用されています。</p>
          <div className={styles.notice}>SpeakerDeck / Slideshare に公開されているスライドから</div>
        </div>
      </section>
    </>
  )
}

export default ThanksForYourSupport
