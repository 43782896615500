import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import * as styles from "./styles.module.scss"

import twitterIcon from "./twitter.svg"
import facebookIcon from "./facebook.svg"

const Header = () => (
  <div className={styles.header}>
    <div className={styles.container}>
      <div className={styles.title}>
        <Link to="/">Azusa 3</Link>
      </div>
      <nav className={styles.nav}>
        <ul>
          <li>
            <a
              className={styles.shareButton}
              href="https://twitter.com/share?text=Azusa+3+-+%E5%A4%A7%E4%BD%93%E3%81%84%E3%81%84%E6%84%9F%E3%81%98%E3%81%AB%E3%81%AA%E3%82%8B%E7%84%A1%E6%96%99Keynote%E3%83%BBGoogle%E3%82%B9%E3%83%A9%E3%82%A4%E3%83%89%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88&amp;url=https%3A%2F%2Fazusa3.sanographix.net&amp;related=sanographix"
            >
              <img src={twitterIcon} alt="Twitter" />
            </a>
          </li>
          <li>
            <a
              className={styles.shareButton}
              href="http://www.facebook.com/sharer.php?u=https%3A%2F%2Fazusa3.sanographix.net"
            >
              <img src={facebookIcon} alt="Facebook" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
