import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./styles.module.scss"

const Features = () => (
  <section className={styles.section}>
    <div className={styles.features}>
      <div className={styles.feature}>
        <StaticImage src="../../images/feature1.jpg" />
        <h3>ダーク／ライトの2パターン</h3>
        <p>ダーク／ライトの2色のカラバリに、汎用性の高いレイアウトを用意。プレゼンのジャンルを選びません。</p>
      </div>
      <div className={styles.feature}>
      <StaticImage src="../../images/feature2.jpg" />
        <h3>日本語環境でも使いやすい</h3>
        <p>テキストまわりを日本語環境用に調整。テンプレートにありがちな使いづらさを軽減すると共に、文字の詰め込みを防止。</p>
      </div>
      <div className={styles.feature}>
        <StaticImage src="../../images/feature3.png" />
        <h3>Googleスライド・Keynote対応</h3>
        <p>Googleスライド版とKeynote版を用意。それぞれに最適化しているので、お好みのプレゼンツールを使えます。</p>
      </div>
    </div>
  </section>
)

export default Features
