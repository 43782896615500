import React from "react"
import * as styles from "./styles.module.scss"
import { StaticImage } from "gatsby-plugin-image"

export default function Others() {
  return (
    <div className={styles.container}>
      <section>
        <h2>
          Made by{" "}
          <a
            href="https://twitter.com/sanographix"
            target="_blank"
            rel="noopener noreferrer"
          >
            sanographix
          </a>
        </h2>
        <nav className={styles.grid}>
          <a
            href="https://illustfolio-lumina.sanographix.net/ja/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/others-illustfolio-lumina.png"
              alt=""
              className={styles.wrapImg}
            />
            <div className={styles.itemContent}>
              <h3>Illustfolio Lumina</h3>
              <p>
                イラストサイトは、このテンプレートで
              </p>
            </div>
          </a>
          <a
            href="https://tokusetsu4.sanographix.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/others-tokusetsu4.png"
              alt=""
              className={styles.wrapImg}
            />
            <div className={styles.itemContent}>
              <h3>Tokusetsu 4</h3>
              <p>同人音楽サイトを最速で作る方法</p>
            </div>
          </a>
          <a
            href="https://kitekure.sanographix.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/others-kitekure.jpg"
              alt=""
              className={styles.wrapImg}
            />
            <div className={styles.itemContent}>
              <h3>Kitekure</h3>
              <p>次のパーティーは、一味違う告知をキメよう</p>
            </div>
          </a>
        </nav>
      </section>
    </div>
  );
}
