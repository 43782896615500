import React from "react"
import * as styles from "./styles.module.scss"

import CTA from "../Cta/Cta"

const GetStarted = () => (
  <section className={styles.section}>
    <h2>Get Started</h2>
    <h3>Download for <strong>Free</strong></h3>
    <CTA />
  </section>

)

export default GetStarted
