import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./styles.module.scss"

const Intro = () => (
  <section className={styles.section}>
    <div className={styles.figure}>
      <div className={styles.image}>
        <StaticImage
          src="../../images/intro.png"
          alt="Azusa 3はどんなプレゼンにも使える汎用テンプレートです"
        />
      </div>
    </div>
    <div className={styles.content}>
      <h2>スライドの<br className={styles.brTouch} />テンプレート探しを<br />終わらせよう</h2>
      <p>
        Azusaは、<strong>大体いい感じのスライドを作るための無料テンプレート</strong>です。汎用的で使い勝手のよいレイアウトなので、カンファレンスや技術勉強会、ライトニングトークなどさまざまなプレゼンテーションの機会で使うことができます。
      </p>
    </div>
  </section>
)

export default Intro
