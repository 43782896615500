import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import HeroHeader from "../components/HeroHeader/HeroHeader"
import Intro from "../components/Intro/Intro"
import Features from "../components/Features/Features"
import ThanksForYourSupport from "../components/ThanksForYourSupport/ThanksForYourSupport"
import Saying from "../components/Saying/Saying"
import GetStarted from "../components/GetStarted/GetStarted"
import FAQ from "../components/Faq/Faq"
import Others from "../components/Others/Others"
import Footer from "../components/Footer/Footer"

const IndexPage = () => (
  <Layout>
    <Seo title="Azusa 3 - 大体いい感じになる無料Keynote・Googleスライドテンプレート" />
    <HeroHeader />
    <Intro />
    <Features />
    <ThanksForYourSupport />
    <Saying />
    <GetStarted />
    <FAQ />
    <Others />
    <Footer />
  </Layout>
)

export default IndexPage
