import React from "react"
import * as styles from "./styles.module.scss"

export const Button = (props) => (
  <>
    <a
      className={styles.button}
      href={props.href}
      target={props.target}
      rel={props.rel}
      onClick={props.onClick}
    >
      {props.children}
    </a>
  </>
)

export default Button
