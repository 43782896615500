import React from "react"
import * as styles from "./styles.module.scss"

const SngrHeader = () => (
  <header className={styles.sngr_header}>
    <div className={styles.sngr_header_inner}>
      <details className={styles.sngr_header_mobileNav}>
        <summary className={styles.sngr_header_mobileNav_toggle}>menu</summary>
        <div className={styles.sngr_header_mobileNav_content}>
          <ul>
            <li>
              <a href="https://sanographix.net/works/">Works</a>
            </li>
            <li>
              <a href="https://text.sanographix.net/">Blog</a>
            </li>
            <li>
              <a href="https://sanographix.net/about/">About</a>
            </li>
          </ul>
        </div>
      </details>
      <div className={styles.sngr_header_logo}>
        <a href="https://sanographix.net/">
          <svg xmlns="http://www.w3.org/2000/svg" width="144" height="14" viewBox="0 0 144 14"><title>SANOGRAPHIX</title><path d="M5.4,14c3.5,0,5.3-1.8,5.3-4S9.4,6.7,6.6,6.2L4.4,5.8c-1.4-.2-2-.9-2-1.8s1-2.1,2.8-2.1S8.4,3.1,8.4,4.4h2.1C10.5,1.9,8.7,0,5.2,0S.3,1.7.3,4A3.6,3.6,0,0,0,3.6,7.6l2.6.5c1.6.3,2.4.9,2.4,1.9s-.9,2-3.2,2S2.1,10.9,2.1,9.6H0C0,12.1,2,14,5.4,14Zm6.3-.2h2.2l1.2-3.2h6.3l1.2,3.2h2.3L19.9.2H16.7Zm4.1-5.1,2.3-6.2h.4l2.3,6.2Zm11.1,5.1H29V3.2h.4L35,13.8h3V.2H36V10.8h-.4L29.9.2h-3Zm20.6.2a6.7,6.7,0,0,0,6.7-6.6V7a6.6,6.6,0,0,0-6.3-7h-.4a6.7,6.7,0,0,0-6.6,6.6V7a6.6,6.6,0,0,0,6.3,7Zm0-1.9C45,12.1,43,10.1,43,7s2-5.1,4.5-5.1,4.6,2,4.6,5.1-2,5.1-4.6,5.1ZM63.1,14a5.6,5.6,0,0,0,4.3-1.9h.1l.2,1.7h1.7V6.7h-6V8.4h3.9c0,1.7-1.9,3.6-4.2,3.6s-4.6-2-4.6-5.1,2-5.1,4.6-5.1a4.3,4.3,0,0,1,4,2.8h2.2A6.3,6.3,0,0,0,63.1,0a6.7,6.7,0,0,0-6.7,6.6V7a6.6,6.6,0,0,0,6.3,7Zm9.5-.2h2.1V8.9h1l4.9,4.9h2.7L78.4,8.9h.5a4,4,0,0,0,4-4V4.6A4.1,4.1,0,0,0,79.2.2H72.6ZM74.7,7V2.1h4.1a2.2,2.2,0,0,1,2,2.4h0a2.2,2.2,0,0,1-2,2.4H74.7Zm9.8,6.8h2.3L88,10.6h6.3l1.2,3.2h2.2L92.7.2H89.5Zm4.1-5.1,2.3-6.2h.4l2.3,6.2Zm11.1,5.1h2.1V8.9h4.1a4,4,0,0,0,4.1-4V4.6A4.1,4.1,0,0,0,106.2.2H99.7ZM101.8,7V2.1h4a2.2,2.2,0,0,1,2.1,2.4h0a2.2,2.2,0,0,1-2,2.4h-4.1Zm11.1,6.8h2V7.9H122v5.9h2V.2h-2V5.9h-7.1V.2h-2Zm14.6,0h2.1V.2h-2.1Zm4,0h2.4l3.7-5.1h.4l3.6,5.1H144l-4.9-7L143.7.2h-2.4L138,5h-.4L134.2.2h-2.4l4.7,6.6Z"/></svg>
        </a>
      </div>
      <nav className={styles.sngr_header_navbar}>
        <ul>
          <li>
            <a href="https://sanographix.net/works/">Works</a>
          </li>
          <li>
            <a href="https://text.sanographix.net/">Blog</a>
          </li>
          <li>
            <a href="https://sanographix.net/about/">About</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
)

export default SngrHeader
