import React from "react"
import * as styles from "./styles.module.scss"
import SayingItem from "../SayingItem/SayingItem"
import Twitter from "./twitter.svg"
import Bookmark from "./bookmark.svg"

const Saying = () => (
  <section className={styles.section}>
    <div className={styles.row}>
      <div className={styles.column}>

        <SayingItem
          name="くまもʕ•͡×•ʔ"
          id="@kumamo_ton"
          paragraph="Azusa死ぬほどお世話になっている"
          link="https://twitter.com/kumamo_tone/status/1257622054481715203"
          time="2020-05-05 19:43:57"
          service="Twitter"
          serviceIcon={Twitter}
        />
        <SayingItem
          name="giginet"
          id="@giginet"
          paragraph="Keynoteテーマのazusaマジで便利"
          link="https://twitter.com/giginet/status/454532745922752512"
          time="2014-04-11 17:13:46"
          service="Twitter"
          serviceIcon={Twitter}
        />
        <SayingItem
          name="ヒハラ@チームを考えるエンジニア"
          id="@bluesbuger"
          paragraph="プレゼン資料がだいたい良い感じになるテンプレートAzusa 3。Keynote用だけじゃなくて、Google Slides用も用意してるのがさすが。こう言うテンプレート他にも出て欲しい。"
          link="https://twitter.com/bluesbuger/status/1286889228530376705"
          time="2020-07-25 14:01:15"
          service="Twitter"
          serviceIcon={Twitter}
        />
      </div>
      <div className={styles.column}>
        <SayingItem
          name="Tatsunori Nakano"
          id="@centerfield77"
          paragraph='LT用のスライド作成に Azusa を使ってみたら本当に "いい感じ" になってきて感動してる'
          link="https://twitter.com/centerfield77/status/1440993044786937864"
          time="2021-09-23 19:54"
          service="Twitter"
          serviceIcon={Twitter}
        />
        <SayingItem
          name="font-da"
          id="id:font-da"
          paragraph="めちゃくちゃ使いやすい。文字情報がたくさん入るしありがたいなー。"
          link="https://b.hatena.ne.jp/font-da/20200506#bookmark-4685273180432183778"
          time="2020-05-06"
          service="Hatena Bookmark"
          serviceIcon={Bookmark}
        />
      </div>
      <div className={styles.column}>
        <SayingItem
          name="kj_hashi"
          id="id:kj_hashi"
          paragraph="おっ、Azusaの新バージョン。以前何度か使わせていただいたけど、ほんと大体いい感じになるんだよね。"
          link="https://b.hatena.ne.jp/kj_hashi/20200505#bookmark-4685273180432183778"
          time="2020-05-05"
          service="Hatena Bookmark"
          serviceIcon={Bookmark}
        />
        <SayingItem
          name="kompiro"
          id="id:kompiro"
          paragraph="Googleスライドで使えるいい感じのテンプレートってあんまり見ない気がする。助かる"
          link="https://b.hatena.ne.jp/kompiro/20200506#bookmark-4685273180432183778"
          time="2020-05-06"
          service="Hatena Bookmark"
          serviceIcon={Bookmark}
        />
      </div>
    </div>
  </section>
)

export default Saying
