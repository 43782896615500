/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import SngrHeader from "./SngrHeader/SngrHeader"
import Header from "./Header/Header"
import "normalize.css"
import * as styles from "./Layout/styles.module.scss"

export default function Layout({ children }) {
  return (
    <>
      <SngrHeader />
      <Header />
      <main className={styles.main}>{children}</main>
    </>
  )
}

