import React from "react"
import * as styles from "./styles.module.scss"

import highlight from "../../images/hero-highlight.svg"

import CTA from "../Cta/Cta"


const HeroHeader = () => (
  <header className={styles.hero}>
    <h1>For Your<br/><span className={styles.highlight}><img src={highlight} alt="" /></span>Next Presentation</h1>
    <p>
      <strong>Azusa 3</strong>
      <span>大体いい感じのスライドを作るための無料テンプレート</span>
    </p>

    <h3>Download for <strong>Free</strong></h3>
    <CTA />
    <div className={styles.credit}>
      Version 3.0<br />
      Created and maintained by <a href="https://twitter.com/sanographix">@sanographix</a>
    </div>

  </header>
)

export default HeroHeader
