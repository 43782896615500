import React from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Button from "../Button/Button"
import * as styles from "./styles.module.scss"

const CTA = () => (
  <>
    <div className={styles.cta}>
      <Button
        href="https://docs.google.com/presentation/d/1zCNQDgWZHHwhHh2pWnv5UGHylEwhB9rMUqAeQPtXZnk/template/preview"
        onClick={e => {
          trackCustomEvent({
            category: "Download",
            action: "Click",
            label: "Google Slides",
          })
        }}
      >
        For Google Slides
      </Button>
      <Button
        href="https://github.com/sanographix/azusa3"
        onClick={e => {
          trackCustomEvent({
            category: "Download",
            action: "Click",
            label: "Keynote",
          })
        }}
      >
        For Keynote
      </Button>
    </div>
    <div className={styles.ctaFooter}>
      <span className={styles.downloaded}><strong>30,000+</strong> Downloaded · </span>
      <details className={styles.downloadHelp}>
        <summary>ダウンロードできませんか？</summary>
        <div className={styles.content}>
          Googleスライド版にアクセスできない場合は、別のブラウザや別のGoogleアカウントで試すか、しばらく時間をおいてアクセスしてみてください。個別の「アクセス権のリクエスト」には応じられません。
        </div>
      </details>
    </div>
  </>
)

export default CTA
