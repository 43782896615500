import { Link } from "gatsby"
import React from "react"
import * as styles from "./styles.module.scss"

const Footer = () => (
  <footer className={styles.section}>
    <p>このサイトは、大体いい感じになるスライドを作るためのテンプレート「Azusa 3」の紹介ページです。</p>
    <p>
      <Link to="/">Azusa 3</Link> template designed by <a href="http://www.sanographix.net/">SANOGRAPHIX</a>
      <br />
      <a href="http://twitter.com/sanographix">Twitter</a>&nbsp;&nbsp;·&nbsp;&nbsp;<a href="http://facebook.com/sanographix.net">Facebook</a>
    </p>
  </footer>
)

export default Footer
