import React from "react"
import * as styles from "./styles.module.scss"
import { Link } from "gatsby"

const FAQ = () => (
  <section className={styles.section}>
    <details><summary>Googleスライド版とKeynote版の差異</summary>
      <strong>フォントの違い</strong>
      <ul>
        <li>Googleスライド版はGoogleフォント（Montserrat）を使用しています。</li>
        <li>Keynote版はMacにバンドルされているフォント（Founders Grotesk, Avenir）を使用しています。</li>
      </ul>
      <strong>図版用スライドレイアウトの違い</strong>
      <ul>
        <li>Keynote版はスライド内にダミーの図版があらかじめ組み込まれていますので、置き換えるだけで簡単にレイアウトを整えられます。</li>
        <li>Googleスライド版は仕様上それができないため、レイアウトに空白を開けてあります。お好みで図版を空きスペースに貼り付けてご利用ください。</li>
      </ul>
      <strong>カラーパレットの違い</strong>
      <ul>
        <li>Googleスライドのみ、文字色などを変更する際カラーパレットからテーマに即した色を素早く選ぶことができます。詳しくは<a href="https://text.sanographix.net/entry/azusa3-google-slides-keynote-template#Google%E3%82%B9%E3%83%A9%E3%82%A4%E3%83%89%E7%89%88%E3%81%AE%E8%A6%8B%E3%81%A9%E3%81%93%E3%82%8D" target="_blank" rel="noopener noreferrer">ブログ記事</a>をご覧ください。</li>
      </ul>
    </details>

    <details><summary>Googleスライドを書き出しするときの注意点</summary>
      <p><strong>GoogleスライドをPDFやPNGで書き出すと、和文フォントが正しく表示されません。</strong>そのため、SpeakerDeckやSlideShareなど外部サイトにアップロードする場合、Keynote版のほうが無難です。</p>
      <p>なお、Googleスライド自体で共有URLや埋め込みコードの発行ができます。スライドを公開する場合はこれらの機能をご利用ください。</p>
    </details>

    <details><summary>テンプレートの使用権とライセンス</summary>
      <ul>
        <li>個人的な目的と商用目的の両方に使用できます。クレジットの表記は不要です。</li>
        <li>また、Azusa 3を改変（例: PowerPointバージョンを制作）することも自由です。改変したAzusa 3を配布する場合、配布サイトに以下の情報を含むクレジットを明記してください。
          <ul>
            <li>Azusa 3を元にしている旨がわかるようにする（クレジット文言は特に指定ございません）</li>
            <li><Link to="/">当サイト</Link>へのリンクを設置する</li>
          </ul>
        </li>
      </ul>
      <p><strong>次のことは禁止します。</strong></p>
      <ul>
        <li>テンプレートの一部または全部を販売すること</li>
      </ul>
    </details>

  </section>
)

export default FAQ
